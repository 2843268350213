import { mapActions } from "vuex";
import Auth from "@/services/Auth";

export default {
  data() {
    return {
      isLogoutLoading: false,
    };
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    async logoutHandler() {
      this.isLogoutLoading = true;
      try {
        const { data } = await Auth.logout();
        this.setSnackbar({ text: data.message, type: "info" });
        this.logout();
      } catch (error) {
        this.isLogoutLoading = false;
      }
    },
    setSnackbar({ text, type }) {
      this.$store.commit("SET_SNACKBAR", {
        type: type,
        text: text,
      });
    },
  },
};
