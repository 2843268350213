import Template from "@/services/Template";
export default {
  namespaced: true,
  state: {
    templates: [],
  },
  getters: {
    templates(state) {
      return state.templates;
    },
  },
  mutations: {
    SET_TEMPLATES(state, payload) {
      state.templates = payload;
    },
  },
  actions: {
    async all({ commit }, payload) {
      const { data } = await Template.all(payload);

      if (data && data.data) {
        commit("SET_TEMPLATES", data.data);
      }
    },
    // eslint-disable-next-line no-unused-vars,no-empty-pattern
    async get({}, payload) {
      return Template.get(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async store({}, payload) {
      return Template.store(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async update({}, payload) {
      return Template.update(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async remove({}, payload) {
      return Template.remove(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async destroy({}, payload) {
      return Template.destroy(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async restore({}, payload) {
      return Template.restore(payload);
    },
  },
};
