import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#7052fb",
        sidebar: "#7052fb",
        loginColor: "#7052fb",
        unlayer: "#40b883",
      },
    },
  },
  icons: {
    iconfont: "mdiSvg" || "mdi" || "mdiSvg" || "md" || "fa" || "fa4" || "faSvg",
  },
});
