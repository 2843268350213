import Sender from "@/services/Sender";
export default {
  namespaced: true,
  state: {
    senders: [],
  },
  getters: {
    senders(state) {
      return state.senders;
    },
  },
  mutations: {
    SET_SENDERS(state, payload) {
      state.senders = payload;
    },
  },
  actions: {
    async all({ commit }, payload) {
      const { data } = await Sender.all(payload);

      if (data && data.data) {
        commit("SET_SENDERS", data.data);
      }
    },
    // eslint-disable-next-line no-unused-vars,no-empty-pattern
    async get({}, payload) {
      return Sender.get(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async store({}, payload) {
      return Sender.store(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async update({}, payload) {
      return Sender.update(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async remove({}, payload) {
      return Sender.remove(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async destroy({}, payload) {
      return Sender.destroy(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async restore({}, payload) {
      return Sender.restore(payload);
    },
  },
};
