import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./auth";
import sender from "./sender";
import template from "./template";
import subscriber from "./subscriber";
import addressbook from "./addressbook";
import importFile from "./import";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    auth,
    sender,
    template,
    subscriber,
    addressbook,
    importFile,
  },
  state: {
    snackBar: {},
    overlay: false,
    drawer: false,
  },
  getters: {},
  mutations: {
    SET_SNACKBAR: function (state, payload) {
      state.snackBar = payload;
    },
    SET_OVERLAY: function (state, payload) {
      state.overlay = payload;
    },
    SET_MINI_VARIANT: function (state, payload) {
      state.drawer = payload;
    },
  },
  actions: {},
});
