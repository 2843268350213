import Api from "./Api";

const all = (payload) => {
  const path = "/subscribers";
  const endpoint = payload
    ? `${
        path +
        "?" +
        Object.keys(payload)
          .map((key) => key + "=" + payload[key])
          .join("&")
      }`
    : path;

  return Api.getRequest({
    endpoint,
  });
};

const getByOne = (payload) => {
  const endpoint = `/subscriber/${payload.id}`;
  return Api.getRequest({
    endpoint,
  });
};

const store = (payload) => {
  return Api.postRequest({
    endpoint: "/subscriber/store",
    data: payload,
  });
};

const update = (payload) => {
  return Api.postRequest({
    endpoint: "/subscriber/update",
    method: "PUT",
    data: payload,
  });
};

const remove = (payload) => {
  return Api.deleteRequest({
    endpoint: `/subscriber/delete/${payload.id}`,
  });
};

const destroy = (payload) => {
  return Api.deleteRequest({
    endpoint: `/subscriber/destroy/${payload.id}`,
  });
};

export default {
  all,
  getByOne,
  store,
  update,
  remove,
  destroy,
};
