import Api from "@/services/Api";
const all = (payload) => {
  const path = "/address-books";
  const endpoint = payload
    ? `${
        path +
        "?" +
        Object.keys(payload)
          .map((key) => key + "=" + payload[key])
          .join("&")
      }`
    : path;

  return Api.getRequest({
    endpoint,
  });
};

const get = (payload) => {
  const endpoint = `/address-book/${payload.id}`;
  return Api.getRequest({
    endpoint,
  });
};

const store = (payload) => {
  return Api.postRequest({
    endpoint: "/address-book/store",
    data: payload,
  });
};

const sendingEmail = (payload) => {
  return Api.postRequest({
    endpoint: "/address-book/sendingEmail",
    data: payload,
  });
};

const update = (payload) => {
  return Api.postRequest({
    endpoint: "/address-book/update",
    method: "PUT",
    data: payload,
  });
};

const remove = (payload) => {
  return Api.deleteRequest({
    endpoint: `/address-book/delete/${payload.id}`,
  });
};

const destroy = (payload) => {
  return Api.deleteRequest({
    endpoint: `/address-book/destroy/${payload.id}`,
  });
};

const restore = (payload) => {
  return Api.getRequest({
    endpoint: `/address-book/restore/${payload.id}`,
  });
};

export default {
  all,
  get,
  store,
  update,
  remove,
  destroy,
  restore,
  sendingEmail,
};
