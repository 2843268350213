<template>
  <v-navigation-drawer
    :color="$vuetify.theme.themes.light.primary"
    dark
    app
    left
    fixed
    clipped
    :mini-variant="miniVariant"
    :mini-variant-width="70"
  >
    <v-card
      :light="!$vuetify.theme.dark"
      class="ma-2"
      color="#fafafa"
      elevation="0"
    >
      <v-card-text>
        <router-link to="/">
          <v-img :src="require('@/assets/logo.png')" max-height="50" />
        </router-link>
      </v-card-text>
    </v-card>
    <v-divider />
    <v-list dense nav>
      <v-list-item v-for="item in items" :key="item.title" link :to="item.path">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn
          v-if="!miniVariant"
          block
          dark
          color="indigo darken-3"
          :loading="isLogoutLoading"
          @click="logoutHandler"
        >
          Logout
        </v-btn>
        <v-btn icon v-else @click="logoutHandler">
          <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logout from "@/mixins/Logout";

export default {
  name: "LeftSidebar",
  data() {
    return {
      isLogoutLoading: false,
      items: [
        { title: "Home", icon: "mdi-home", path: "/" },
        {
          title: "Create Campaign",
          icon: "mdi-cloud-upload",
          path: "/campaign",
        },
        { title: "Address Books", icon: "mdi-book", path: "/address-books" },
        { title: "Subscribers", icon: "mdi-gmail", path: "/subscribers" },
        {
          title: "Email Templates",
          icon: "mdi-message-settings",
          path: "/templates",
        },
        { title: "Senders", icon: "mdi-file-send", path: "/senders" },
        { title: "Import Files", icon: "mdi-import", path: "/import-emails" },
      ],
    };
  },
  mixins: [Logout],
  computed: {
    ...mapState({
      miniVariant: (state) => state.drawer,
    }),
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods: {
    userMenuHandler(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.sidebar {
  height: 100vh !important;
  top: 64px !important;
  transform: translateX(0%) !important;
  width: 220px !important;
  max-height: calc(100% - 64px) !important;
}
</style>
