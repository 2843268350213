import Api from "@/services/Api";

const store = (payload) => {
  return Api.postWithFormData({
    endpoint: "/import/emails",
    data: payload,
  });
};

export default {
  store,
};
