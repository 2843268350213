<template>
  <v-app>
    <v-main>
      <router-view name="header" />
      <router-view />
      <router-view name="footer" />
      <snackbar-component />
    </v-main>
  </v-app>
</template>

<script>
import SnackbarComponent from "@/components/Snackbar";
export default {
  name: "App",
  metaInfo: {
    title: "Mailbox",
    titleTemplate: "Home | %s",
    meta: [
      { name: "robots", content: "noindex" },
      { name: "googlebot", content: "noindex" },
    ],
  },
  components: { SnackbarComponent },
  data: () => ({}),
};
</script>

<style lang="scss">
.text-transform-capitalize {
  text-transform: capitalize !important;
  font-size: 18px !important;
}
</style>
