<template>
  <v-footer padless>
    <v-card flat tile class="grey lighten-5 text-center" width="100%">
      <v-divider></v-divider>
      <v-card-text class="blue-grey--text">
        {{ new Date().getFullYear() }} — <strong>Mailbox</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "FooterPage",
};
</script>

<style scoped></style>
