import Subscriber from "@/services/Subscriber";
export default {
  namespaced: true,
  state: {
    subscribers: [],
  },
  getters: {
    subscribers(state) {
      return state.subscribers;
    },
  },
  mutations: {
    SET_SUBSCRIBERS(state, payload) {
      state.subscribers = payload;
    },
  },
  actions: {
    async all({ commit }, payload) {
      const { data } = await Subscriber.all(payload);
      if (data && data.data) {
        commit("SET_SUBSCRIBERS", data.data);
      }
    },
    // eslint-disable-next-line no-unused-vars,no-empty-pattern
    async get({}, payload) {
      return Subscriber.get(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async store({}, payload) {
      return Subscriber.store(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async update({}, payload) {
      return Subscriber.update(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async remove({}, payload) {
      return Subscriber.remove(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async destroy({}, payload) {
      return Subscriber.destroy(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async restore({}, payload) {
      return Subscriber.restore(payload);
    },
  },
};
