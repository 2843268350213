import Import from "@/services/Import";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async store({}, payload) {
      return Import.store(payload);
    },
  },
};
