<template>
  <div>
    <left-sidebar />
    <v-app-bar color="grey lighten-4" flat>
      <v-app-bar-nav-icon @click.stop="onClickedVariant()"></v-app-bar-nav-icon>
      <v-text-field
        label="Search ..."
        class="pt-5"
        filled
        prepend-inner-icon="mdi-magnify"
        dense
        solo
        flat
        background-color="grey lighten-5"
      />
      <v-spacer></v-spacer>
      <v-menu
        v-if="authenticated"
        rounded="lg"
        offset-y
        open-on-hover
        transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <div v-bind="attrs" v-on="on">
            <span>{{ user.name }}</span>
            <v-avatar size="30" class="ml-2">
              <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>
            </v-avatar>
          </div>
        </template>
        <v-list>
          <v-list-item
            v-for="item in userMenu"
            :key="item.title"
            link
            @click="userMenuHandler(item.route)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-btn
              elevation="0"
              :loading="isLogoutLoading"
              @click="logoutHandler"
            >
              Logout
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logout from "@/mixins/Logout";
import LeftSidebar from "@/components/menu/LeftSidebar";
export default {
  name: "TopBar",
  components: { LeftSidebar },
  mixins: [Logout],
  data() {
    return {
      userMenu: [
        { title: "My Account", route: "/" },
        { title: "Settings", route: "/" },
      ],
    };
  },
  computed: {
    ...mapState({
      miniVariant: (state) => state.drawer,
    }),
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods: {
    userMenuHandler(path) {
      this.$router.push(path);
    },
    onClickedVariant() {
      if (this.miniVariant) {
        this.$store.commit("SET_MINI_VARIANT", false);
      } else {
        this.$store.commit("SET_MINI_VARIANT", true);
      }
    },
  },
};
</script>

<style scoped>
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #eeeeee !important;
}
</style>
