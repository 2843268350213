//import HomeView from "@/views/Home";
import Header from "@/layouts/TopBar";
import FooterPage from "@/layouts/FooterPage";

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/Login"),
    meta: { guestOnly: true },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/auth/Signup"),
    meta: { guestOnly: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/auth/ForgotPassword"),
    meta: { guestOnly: true },
  },
  {
    path: "/auth/email/verify",
    name: "AuthEmailVerify",
    component: () => import("../views/auth/Verify"),
  },
  {
    path: "/auth/reset-password",
    name: "AuthResetPassword",
    component: () => import("../views/auth/ResetPassword"),
    meta: { guestOnly: true },
  },
  {
    path: "/",
    name: "home",
    components: {
      header: Header,
      default: () => import("@/views/Home"),
      footer: FooterPage,
    },
    meta: { authOnly: true },
  },
  {
    path: "/about",
    name: "about",
    components: {
      header: Header,
      default: () => import("@/views/About"),
      footer: FooterPage,
    },
    meta: { authOnly: true },
  },
  {
    path: "/senders",
    name: "senders",
    components: {
      header: Header,
      default: () => import("@/views/Senders"),
      footer: FooterPage,
    },
    meta: { authOnly: true },
  },
  {
    path: "/templates",
    name: "Templates",
    components: {
      header: Header,
      default: () => import("@/views/templates/Templates"),
      footer: FooterPage,
    },
    meta: { authOnly: true },
  },
  {
    path: "/templates/management",
    name: "TemplatesManagement",
    components: {
      header: Header,
      default: () => import("@/views/templates/TemplatesManagement"),
      footer: FooterPage,
    },
    meta: { authOnly: true },
  },
  {
    path: "/templates/management/editor",
    name: "TemplatesManagementEditor",
    components: {
      header: Header,
      default: () => import("@/views/templates/AddNewEmailTemplate"),
      footer: FooterPage,
    },
    meta: { authOnly: true },
  },
  {
    path: "/subscribers",
    name: "subscribers",
    components: {
      header: Header,
      default: () => import("@/views/Subscribers"),
      footer: FooterPage,
    },
    meta: { authOnly: true },
  },
  {
    path: "/address-books",
    name: "addressBook",
    components: {
      header: Header,
      default: () => import("@/views/AddressBooks"),
      footer: FooterPage,
    },
    meta: { authOnly: true },
  },
  {
    path: "/import-emails",
    name: "importEmails",
    components: {
      header: Header,
      default: () => import("@/views/ImportEmails"),
      footer: FooterPage,
    },
    meta: { authOnly: true },
  },
  {
    path: "/campaign",
    name: "campaign",
    components: {
      header: Header,
      default: () => import("@/views/Campaign"),
      footer: FooterPage,
    },
    meta: { authOnly: true },
  },
];

export default routes;
